import handleArrayWIthProperties_ypljaokOlv from "/vercel/path0/app/plugins/handleArrayWIthProperties.ts";
import revive_payload_client_T7uiCZqgeL from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_@upstash+redis@1.34.3_@vercel+kv@3.0.0__vudlk7wwnv2l5lclvtjz2ngbym/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import vuetify_icons_ritJ3E8MDK from "/vercel/path0/node_modules/.pnpm/vuetify-nuxt-module@0.18.3_magicast@0.3.5_rollup@4.34.6_typescript@5.7.3_vite@6.1.0_@types+no_nvv454cu75aeqy5mkknzuf32jy/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-icons.js";
import unhead_vmitOwRLO9 from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_@upstash+redis@1.34.3_@vercel+kv@3.0.0__vudlk7wwnv2l5lclvtjz2ngbym/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_YyiP8zF8Cd from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_@upstash+redis@1.34.3_@vercel+kv@3.0.0__vudlk7wwnv2l5lclvtjz2ngbym/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_6TkDmjxxhx from "/vercel/path0/node_modules/.pnpm/@nuxtjs+supabase@https+++github.com+dan-hale+supabase+releases+download+v1.4.6-0+nuxtjs-supabase-1.4.6-0.tgz/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.js";
import payload_client_wkFMH0JQgI from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_@upstash+redis@1.34.3_@vercel+kv@3.0.0__vudlk7wwnv2l5lclvtjz2ngbym/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_Lvwt9VKet8 from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_@upstash+redis@1.34.3_@vercel+kv@3.0.0__vudlk7wwnv2l5lclvtjz2ngbym/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_epmL8nUpzf from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_@upstash+redis@1.34.3_@vercel+kv@3.0.0__vudlk7wwnv2l5lclvtjz2ngbym/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import view_transitions_client_mmI1JKkVcI from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_@upstash+redis@1.34.3_@vercel+kv@3.0.0__vudlk7wwnv2l5lclvtjz2ngbym/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_ASmuQ97zGg from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_@upstash+redis@1.34.3_@vercel+kv@3.0.0__vudlk7wwnv2l5lclvtjz2ngbym/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import cross_origin_prefetch_client_gqQWdhya2e from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_@upstash+redis@1.34.3_@vercel+kv@3.0.0__vudlk7wwnv2l5lclvtjz2ngbym/node_modules/nuxt/dist/app/plugins/cross-origin-prefetch.client.js";
import components_plugin_zlvi6dcIsi from "/vercel/path0/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_Edin1lnYCw from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_@upstash+redis@1.34.3_@vercel+kv@3.0.0__vudlk7wwnv2l5lclvtjz2ngbym/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_udASYHn3he from "/vercel/path0/node_modules/.pnpm/@nuxt+icon@1.10.3_magicast@0.3.5_rollup@4.34.6_vite@6.1.0_@types+node@22.13.1_jiti@2.4.2_sass_w2rvvamnpxjtlbiyar5swqv2si/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import auth_redirect_SsyCiUwvUY from "/vercel/path0/node_modules/.pnpm/@nuxtjs+supabase@https+++github.com+dan-hale+supabase+releases+download+v1.4.6-0+nuxtjs-supabase-1.4.6-0.tgz/node_modules/@nuxtjs/supabase/dist/runtime/plugins/auth-redirect.js";
import unocss_RAHspWIKy3 from "/vercel/path0/node_modules/.cache/nuxt/.nuxt/unocss.mjs";
import vuetify_no_client_hints_Yz5WikjbVc from "/vercel/path0/node_modules/.pnpm/vuetify-nuxt-module@0.18.3_magicast@0.3.5_rollup@4.34.6_typescript@5.7.3_vite@6.1.0_@types+no_nvv454cu75aeqy5mkknzuf32jy/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-no-client-hints.js";
import custom_icons_039LGJjGSP from "/vercel/path0/app/plugins/custom-icons.ts";
import directives_UCglB6puIs from "/vercel/path0/app/plugins/directives.ts";
import vuetify_nuxt_plugin_client_moE2XX1Cig from "/vercel/path0/node_modules/.cache/nuxt/.nuxt/vuetify-nuxt-plugin.client.mjs";
export default [
  handleArrayWIthProperties_ypljaokOlv,
  revive_payload_client_T7uiCZqgeL,
  vuetify_icons_ritJ3E8MDK,
  unhead_vmitOwRLO9,
  router_YyiP8zF8Cd,
  supabase_client_6TkDmjxxhx,
  payload_client_wkFMH0JQgI,
  navigation_repaint_client_Lvwt9VKet8,
  check_outdated_build_client_epmL8nUpzf,
  view_transitions_client_mmI1JKkVcI,
  chunk_reload_client_ASmuQ97zGg,
  cross_origin_prefetch_client_gqQWdhya2e,
  components_plugin_zlvi6dcIsi,
  prefetch_client_Edin1lnYCw,
  plugin_udASYHn3he,
  auth_redirect_SsyCiUwvUY,
  unocss_RAHspWIKy3,
  vuetify_no_client_hints_Yz5WikjbVc,
  custom_icons_039LGJjGSP,
  directives_UCglB6puIs,
  vuetify_nuxt_plugin_client_moE2XX1Cig
]